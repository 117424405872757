import React from 'react';

import {AuthTemplate} from '~src/components';
import Providers from '~src/components/Providers';
import Content from '~src/components/organisms/AdminOrganism/Auth/RecoveryStart/Content';

const RecoveryStartPage: React.FC<{}> = () => {
  return (
    <Providers>
      <AuthTemplate>
        <Content />
      </AuthTemplate>
    </Providers>
  );
};

export default RecoveryStartPage;
