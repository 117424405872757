import React, {Fragment, useContext} from 'react';
import {Button, Col, Form, Input, Row, Space, Typography} from 'antd';
import {Routes} from '~src/utils/routes';
import styled from 'styled-components';
import {useStoreon} from 'storeon/react';
import {IEvents, IState} from '~src/store';
import {LocaleContext} from '~src/context';
import * as colors from '@ant-design/colors';
import {Link} from 'gatsby';

const {Title, Text} = Typography;

const IconStyled = {
  color: colors.presetPrimaryColors.blue,
};

interface IContentProps {}

const validateMessages = {
  required: 'Обязательное поле',
  types: {
    email: 'Это должен быть email',
  },
};
interface IContentProps {}

const Content: React.FC<IContentProps> = () => {
  const {dispatch, auth} = useStoreon<IState, IEvents>('auth');

  const {
    pages: {
      admin: {auth: authContext},
    },
  } = useContext(LocaleContext);

  // TODO: разобраться с типами - в AntD по дефолту стоит any
  const handleResetSubmit = values => {
    dispatch('auth/recovery-start', values);
  };

  return (
    <RowStyled gutter={[20, 20]}>
      {!auth.isReset && (
        <Col span={24}>
          <Space size={30} direction={'vertical'}>
            <Space size={15} direction={'vertical'}>
              <TitleStyled level={4}>{authContext.passwordReset.resetTitle}</TitleStyled>
              <TextStyled>{authContext.passwordReset.resetSubtitle}</TextStyled>
            </Space>
            <FormStyled
              name="reset-form"
              validateMessages={validateMessages}
              onFinish={handleResetSubmit}
              size={'large'}
            >
              <Form.Item
                name="userEmail"
                validateTrigger={['onBlur', 'onSubmit']}
                rules={[
                  {
                    type: 'email',
                    required: true,
                  },
                ]}
              >
                <Input
                  prefix={<authContext.icons.emailIcon style={IconStyled} />}
                  name={'userEmail'}
                  placeholder={authContext.userName.usernamePlaceholder}
                />
              </Form.Item>
              <ButtonStyled block type="primary" htmlType="submit">
                {authContext.controls.submitResetButtonText}
              </ButtonStyled>
            </FormStyled>
          </Space>
          <Row justify={'center'}>
            <Button type={'link'} size={'large'}>
              <Link to={Routes.signIn}>{authContext.controls.goBackButtonText}</Link>
            </Button>
          </Row>
        </Col>
      )}
      {auth.isReset && (
        <Fragment>
          <Col span={24}>
            <authContext.successScreen.Icon style={{fontSize: 50, color: colors.presetPrimaryColors.green}} />
          </Col>
          <Col span={24}>
            <TitleStyled level={4}>{authContext.successScreen.title}</TitleStyled>
          </Col>
          <Col span={24}>
            <TextStyled>{authContext.successScreen.caption}</TextStyled>
          </Col>
          <Col span={24}>
            <Button type={'primary'} size={'large'}>
              <Link to={Routes.signIn}>{authContext.controls.goBackButtonText}</Link>
            </Button>
          </Col>
        </Fragment>
      )}
    </RowStyled>
  );
};

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:last-of-type(FormItemStyled) {
    margin-bottom: 0;
  }
`;

const TitleStyled = styled(Title)`
  display: flex;
  justify-content: center;
`;

const TextStyled = styled(Text)`
  display: flex;
  font-size: 12px;
  text-align: center;
`;

const ButtonStyled = styled(Button)`
  margin-bottom: 10px;
`;

const RowStyled = styled(Row)`
  text-align: center;
`;

export default Content;
